<template>
  <div class="row">
    <div class="col-md-12">
      <dashboard-box :title="$t('waybill.product_tag')">
        <template v-slot:preview>
          <div class="row px-10 py-5" id="pdf-holder">
            <div class="col-12 mb-5">
              <h2 class="text-center"><span class="button-border">{{ $t('waybill.waybill_product_tag')}}</span></h2>
            </div>
            <div class="">
              <qrcode-vue :class-name="'mr-2'" :value="waybill.code_link" size="350" level="H"></qrcode-vue>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 row">
              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.waybill_type')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">{{ waybill.waybill_type_id }}</p>
              </div>
              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.order_date')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">{{ waybill.order.order_date | momentDateFormat }}</p>
              </div>

              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.supplier_company')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">{{ waybill.company_id }}</p>
              </div>

              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.document_no')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">75795</p>
              </div>
              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.product_name')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">Mor Cizgili</p>
              </div>

              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.product_color')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">Pembe</p>
              </div>
              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.net_incoming_amount')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg"> {{ parseInt(waybill.net_amount) }} {{ waybill.unit_id }}</p>
              </div>
              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.no_of_roll')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg"> {{ waybill.fabric_roll }}</p>
              </div>


              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.part_number')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg"> {{ waybill.part_number }}</p>
              </div>
              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.depo_type')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg"> {{ waybill.store_id }}</p>
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from 'vuex'
import QrcodeVue from 'qrcode.vue';
import Vue from "vue";
import VueHtml2Canvas from "vue-html2canvas";
import {GET_ITEM_DETAIL_BY_ID} from "@/core/services/store/REST.module";
import moment from "moment";
import {LARAVEL_DATE_FORMAT, LARAVEL_DATE_TIME_FORMAT} from "@/core/config/constant";
Vue.use(VueHtml2Canvas);

export default {
  name: "WaybillTag",
  components: {
    DashboardBox,
    QrcodeVue,
  },
  computed: {
    ...mapGetters({

    }),
  },
  data() {
    return {
      waybill: null,
      id: this.$route.params.id,
    };
  },
  methods: {
    ...mapMutations({

    }),
  },
  mounted() {
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('waybill.waybill'), route: "waybill.index" },
      { title: this.$t('waybill.waybill_pdf') }
    ]);

    this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
      url: 'api/waybills/' + this.id,
      id: this.id,
      acceptPromise: true
    }).then((result) => {
      if(result.status){
        self.waybill = result.data;
        self.waybill.waybill_date = moment(self.waybill.waybill_date, LARAVEL_DATE_FORMAT)
        self.waybill.created_at = moment(self.waybill.created_at, LARAVEL_DATE_TIME_FORMAT)
        self.waybill.order.order_date = moment(self.waybill.order.order_date, LARAVEL_DATE_FORMAT)

        setTimeout(function(){
          self.downloadPdf('pdf-holder', self.waybill.order.model)
        }, 1000);
      }
    })
  }
}
</script>

<style scoped>
.button-border{
  border-bottom: 1px solid rgba(150, 150, 150, 0.4);
}
</style>
